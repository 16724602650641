<template>
  <div class="page">
    <b-container>
      <b-row>
        <b-col>
          <div class="error-container">
            <svg-icon class-name="error__icon" icon-class="404" />
            <div class="error__tips py-4">頁面不存在</div>
            <div class="error__btn">
              <b-button variant="primary">
                <b-link to="/">返回首頁</b-link>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Error404",
  components: {},
  data() {
    return {
      name: "",
    };
  },
  methods: {},
  created() {
    this.name = this.$route.params.name;
  },
};
</script>

<style lang="scss" scoped>
.page {
  margin: 0;
  padding: 0;
  height: 100vh;

  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .error__icon {
    width: 400px;
    height: 50vh;
    margin-top: 10vh;
  }

  .error__tips {
    font-size: 1.5rem;
    color: #ddd;
    position: relative;
    margin-top: -40px;
  }

  a {
    color: #eee;
    text-decoration: none;
  }

  .error__btn:hover {
    a {
      color: #fff;
    }
  }
}
</style>
